@import '/setting';
body{
  .ad-model-tooltip{
    background: $white !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.4);
    text-align: left;
    min-width: 240px;
    ul{
      li{
        margin-bottom: 14px;
        &:last-child{
          margin-bottom: 0;
        }
        h6{
          font-size: $font14;
          color: $black;
          font-family: $ibmplexRegular;
          a{
            color: $black;
          }
        }
      }
    }
  }
  .sparta-secondary-sidbar{
    position: fixed;
    z-index: 0;
    padding: 0 0 32px;
    background-image: linear-gradient(-180deg,#6538db,#0a56a8);
    width: 196px;
    left: 40px;
    bottom: 0;
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    top: 94px;
    margin-left: 30px;
    ul{
      li{
        a{
          display:block;
          padding-left: 32px;
          cursor: pointer;
          font-family: $ibmplexRegular;
          font-size:12px;
          color:$color_fff !important;
          padding: 10px 15px;
          &:hover{
            color:$color_fff !important;
          }
          &.active{
            background: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
            background: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
              color:$color_fff;
              &:hover{
                color:$color_fff;
              }
          }
        }
      }
    }
  }
  .sparta-secondary-header{
    position: fixed;
    right: 0;
    top: 40px;
    left: 70px;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    -moz-box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    margin: 0;
    padding: 0 5px;
    .asset-switcher-and-apps{
      ul{
        li{
          &:first-child{
            img{
              width: 18px;
            }
          }
          &.asset-sw{
            width: 157px;
            padding: 0 0 0 10px;
            h3{
              @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexBold);
              span{
                @include elementStyles($color: #464646, $fontFamily: $ibmplexLight);
              }
            }
          }
          &.application-type{
            border-left: 1px solid #d7d7d7;
            padding: 5px 0 5px 8px;
            h4{
              @include elementStyles($size: 16px, $color: #BD10E0, $fontFamily: $ibmplexRegular);
            }
            a{
              @include elementStyles($size: 12px, $color: #2F8CFF, $fontFamily: $ibmplexRegular);
              img{
                width: 15px;
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
  }
  .table-ui-component{
    margin-top: 25px;
    .bulk-ops-cn{
      .add-bulk-operations{
        background: #ffffff;
        padding:10px;
        border: 1px solid #EAEAEA;
        border-bottom: none;
        &.selected-operations{
          background: #F3F7FF;
        }
        ul{
          display: inline-block;
          li{
            margin-right: 20px;
            font-size: 14px;
            font-family: $ibmplexRegular;
            color: #000000;
            &.operation-cta{
              color: $linkBlue;
              cursor: pointer;
            }
          }
        }
        .aims-primary-btn{
          float: right;
          padding: 5px 20px;
          position: relative;
          font-size: 14px;
          top: -5px;
          color: $color_fff;
          background-color: #3f51b5;
          border-color: #3f51b5;
          border-radius: 3px;
          text-transform: uppercase;
          // @extend %primary-botton;
        }
      }
    }
    .table-list-filter{
      padding: 5px 10px 7px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #EAEAEA;
      .add-filter-type{
        .add-filter{
          position: relative;
          top: 1.3px;
          .close-block{
            width:10px;
            position: relative;
            top:-1px;
            margin-right: 4px;
          }
          .plus-icon{
            width:10px;
            margin-right:4px;
          }
          p{
            font-size: $font12;
            font-family: $ibmplexRegular;
            color: #3f51b5;
            cursor: pointer;
            display: inline-block;
            padding: 4px 17px;
            border-radius: 20px;
            border: 1px dashed #3f51b5;
            background: $color_fff;
            float: left;
            margin: 0 20px 0px 0;
          }
          ul.applied-filter{
            .close-block{
              width: 8px;
              position: relative;
              top: 0;
            }
            li{
              float: left;
              padding: 2px 30px 2px 16px;
              margin-right: 5px;
              position: relative;
              margin-bottom: 4px;
              background:#E0EEFF;
              border-radius:3px;
              font-family:$ibmplexRegular;
              font-size:12px;
              color:#000000;
              letter-spacing:0.22px;
              text-align:left;
              top:3px;
              span{
                cursor: pointer;
                position: absolute;
                right: 0;
                top: -3px;
                color: #C7DBE4;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                font-size: 16px;
              }
            }
          }
          ul.filter-list{
            max-height:400px;
            overflow: auto;
            &::-webkit-scrollbar{
              display: none;
            }
            border: 1px solid #dfe3e6;
            border-top:1px solid #dfe3e6;
            background: #fff;
            width: 400px;
            position: absolute;
            top: 43px;
            z-index: 2;
            box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
            li{
              cursor: pointer;
              font-family: $ibmplexRegular;
              font-size: 12px;
              padding:10px 15px;
              border-bottom:1px solid #dfe3e6;
              transition: all 0.5s ease;
              &:last-child{
                border-bottom:none;
              }
              &:hover{
                background: #f5f5f5;
              }
            }
          }
          .match-block{
            background: $color_fff;
            width:320px;
            box-shadow: 0 15px 34px -10px rgba(0,0,0,.2);
            position: absolute;
            z-index: 2;
            top: 0;
            .match-header{
              background: #3f51b5;
              padding:10px 15px;
              h4{
                font-size: 16px;
                font-family: $ibmplexRegular;
                color:$color_fff;
              }
              span{
                cursor: pointer;
                float:right;
                color:$color_fff;
              }
            }
            .match-body{
              padding: 20px 15px 0;
              >div{
                width: 94%;
                float: left;
              }
              label{
                display: block;
                font-size: 14px;
                font-family: $ibmplexRegular;
                margin-bottom:0;
                color:#626262;
              }
              input[type="text"]{
                border-bottom:1px solid #838383;
                height:30px;
              }
              .Select-control{
                background:#ffffff;
                border:none;
                border-bottom:1px solid #dbdbdb;
                border-radius:3px;
                height:28px;
                width: 80%;
                font-family:$ibmplexRegular;
                border-radius:0;
                .Select-multi-value-wrapper{
                  font-size: 12px;
                }
                .Select-placeholder, .Select-value-label{
                  font-family:$ibmplexRegular;
                  font-size:12px;
                  color:#cacaca;
                  text-align:left;
                  font-family:$ibmplexRegular;
                }
              }
              .react-datepicker-wrapper{
                margin-bottom: 12px;
                input{
                  font-size: 12px;
                  color: #000;
                  &:focus{
                    border-bottom:1px solid #007bff;
                  }
                }
              }
              .Select--multi .Select-value {
                background-color: #ebf5ff;
                background-color: rgba(0, 126, 255, 0.08);
                border-radius: 2px;
                border: 1px solid #368FFC;
                color: #007eff;
                display: inline-block;
                font-size: 0.9em;
                line-height: 1.4;
                margin-left: 5px;
                margin-top: 5px;
                vertical-align: top;
                .Select-value-icon{
                  cursor: pointer;
                  border-bottom-left-radius: 2px;
                  border-top-left-radius: 2px;
                  border-right: 1px solid #007eff;
                  padding: 1px 5px 3px;
                }
                .Select-value-label{
                  color: #007eff;
                }
              }
            }
            .match-footer{
              text-align: right;
              padding:15px;
              padding: 28px 15px 15px;
              input[type="submit"]{
                text-align: right;
                font-size: 12px;
                padding:6px 15px;
              }
            }
          }
        }
      }
    }
    .table-height{
      border-left:1px solid #eaeaea;
      border-right: 1px solid #eaeaea;
      background: #fff;
    }
    table{
      width: 100%;
      &.hover-table{
        tr{
          border-bottom:1px solid #dfe3e6;
          &:last-child{
            border-bottom: none;
          }
          td{
            &:last-child{
              ul{
                opacity: 0;
              }
            }
          }
          cursor: pointer;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
          transition: all 0.2s ease;
          &:hover{
            background: #F3F7FF;
            position: relative;
            z-index: 99;
            td{
              &:last-child{
                ul{
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      thead{
        background:#fafafa;
        // border:1px solid #d7d7d7;
        tr{
          th{
            @include elementStyles($size: 12px, $color: #8c9ba5, $fontFamily: $ibmplexMedium, $padding: 15px 10px);
            background: #fafafa;
            &.check-opt{
              padding-right: 0;
            }
            span{
              cursor: pointer;
              position: relative;
              display: inline-block;
              padding-right:7px;
              color: #6d9bb9;
              b{
                position: absolute;
                right: -15px;
                transform: translate(-50%,-50%);
                top: 56%;
                sup, sub{
                  font-size: 7px;
                  &.asc-sort{
                    position: relative;
                    right: -8.5px;
                    top: -6px;
                  }
                  &.asc-sort-safari {
                    position: relative;
                    right: -10.5px;
                    top: -6px;
                  }
                }
              }

            }
          }
          thead{
            border: none !important;
          }
        }
      }
      tbody{
        background: #ffffff;
        .no-rcrd-found-tbody{
          border:none;
            &:hover{
              background: #fff;
            }
            .no-records-found{
              padding:100px 0;
            }
          }
        tr{
          &.no-border{
           border:none;
          }
          border-bottom: 1px solid $borderGrey;
          td{
            position: relative;
            font-size: 13px;
            font-family: $ibmplexRegular;
            color: #000000;
            padding:5px 10px;
            &.check-opt{
              padding-right: 0;
            }
            .table-action-col{
              float: right;
              li{
                margin-right: 15px;
                cursor: pointer;
              }
            }
            &.crud-block{
              width: 130px;
            }
          }
          &:hover{
            td{
              &:last-child{
                ul{
                  display: inline-block;
                  width: auto;
                }
              }
            }
          }
          &.selected-row{
            background: #EDF5FF;
          }
          &:nth-child(odd){
            background: #ebeff6;
          }
          &:nth-child(even){
            background: #f5f7fa;
          }
          &:hover{
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
    .table-footer{
      background: #F6F6F6;
      padding: 10px;
      border:1px solid #dfe3e6;
      // border-top: none;
      font-size: 14px !important;
      .per-page{
        span{
          font-size: 14px;
          font-family: $ibmplexRegular;
          color: #9F9F9F;
        }
      }
      .page-info{
        float: right;
        li{
          span{
            font-size: 14px;
            font-family: $ibmplexRegular;
            color: #000000;
            padding: 0 4px;
          }
        }
      }
    }
  }
  .break-words-all{
    word-break: break-all;
  }
  .airworthiness-directive-view{
    .title-back{
      margin-bottom: 20px;
      a{
        font-size: 14px;
        color: $linkBlue;
        font-family: $ibmplexRegular;
        img{
          margin-right: 6px;
        }
        &:hover{
          color: $linkBlue;
        }
      }
    }
    .ad-logs{
      background: $white;
      padding: 30px 0;
      margin: 0 0 30px 0;
      img{
        float: left;
      }
      h4, h3, span{
        @include elementStyles($size: 14px, $color: #2F8CFF, $fontFamily: $ibmplexRegular, $margin: '0 0 10px 0');
      }
      h3{
        color: $black;
      }
      p{
        @include elementStyles($size: 11px, $color: #909090, $fontFamily: $ibmplexRegular);
      }
      span{
        color: $black;
        img{
          position: relative;
          top: 6px;
          margin-right: 4px;
        }
      }
      .float-blk{
        margin-left: 26px;
        &.user-info{
          margin-left: 40px;
        }
      }
    }
  }
  .ck-editor-view-mode{
    h1{
      font-size: 28px;
      font-family: $ibmplexMedium;
    }
    h2{
      font-size: 24px;
      font-family: $ibmplexMedium;
    }
    h3{
      font-size: 20px;
      font-family: $ibmplexMedium;
    }
    h4{
      font-size: 18px;
      font-family: $ibmplexMedium;
    }
    h5{
      font-size: 16px;
      font-family: $ibmplexMedium;
    }
    h6{
      font-size: 14px;
      font-family: $ibmplexMedium;
    }
    p{
      font-size: 16px;
      font-family: $ibmplexRegular;
    }
    img{
      max-width: 100%;
    }
    a, span{
      font-size: 14px;
      font-family: $ibmplexRegular;
    }
    ul, ol {
      li{
        font-size: 14px;
        font-family: $ibmplexRegular;
      }
    }
  }
}
