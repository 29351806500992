$primary-color: #555;
$border-color: rgba(0, 0, 0, 0.2);
$padding: 10px;
$border-radius: 4px;

.asset-detail-card {
    display: flex;
    padding: 10px 10px 10px 10px;
    border-radius: $border-radius;
    border: 1px solid $border-color;

    &__content {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        width: 100%;
        border-bottom: 1px solid $border-color;

        &:last-child {
            border: none;
        }
    }

    &__info {
        display: flex;
        // align-items: center;
        width: 50%;

        .asset-image {
            height: 20px;
            width: 20px;
            margin-top: 5px;
        }

        .asset-text {
            margin-left: 10px;
            width: 200px;
            font-family: "Conv_IBMPlexSans-Regular";
            font-size: 12px;
            color: #7f8fa4;

            .msn-wrapper {
                font-family: "Conv_IBMPlexSans-Regular";
                font-size: 14px;
                color: #354052;
                line-height: 14px;

            }

            .date-info {
                margin-top: 10px;
                font-family: "Conv_IBMPlexSans-Regular";

                .date-label {
                    font-weight: bold;
                    font-family: "Conv_IBMPlexSans-Regular";
                    font-size: 12px;
                    color: #7f8fa4;
                }
            }
        }
    }

    &__status {
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .status-indicator {
            background-color: $primary-color;
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }

        .status1 {
            background-color: #9ed5ff;
        }

        .status2 {
            background-color: #9b9a9b;
        }

        .status3 {
            background-color: #3f51b5;
        }

        .status7 {
            background-color: #396a07;
        }

        .status-label {
            color: $primary-color;
            margin-top: 5px;
        }

        .date-label {
            font-weight: bold;
            font-family: "Conv_IBMPlexSans-Regular";
            font-size: 12px;
            color: #7f8fa4;
        }

        .end-date {
            margin-top: 18px;
        }

    }
}

.manpower-popover-card {
    // display: flex;
    padding: 10px 10px 10px 10px;
    border-radius: $border-radius;
    border: 1px solid $border-color;

    &__content {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        padding-bottom: 4px;
        margin-bottom: 4px;
        width: 100%;
        border-bottom: 1px solid $border-color;

        &:last-child {
            border: none;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        // width: 50%;

        .asset-image {
            height: 20px;
            width: 20px;
            margin-top: 5px;
        }

        .asset-text {
            margin-left: 10px;
            // width: 200px;
            font-family: "Conv_IBMPlexSans-Regular";
            font-size: 12px;
            color: #7f8fa4;

            .msn-wrapper {
                font-family: "Conv_IBMPlexSans-Regular";
                font-size: 14px;
                color: #354052;
                line-height: 14px;
                font-weight: 600;

            }

            .date-info {
                margin-top: 10px;
                font-family: "Conv_IBMPlexSans-Regular";

                .date-label {
                    font-weight: bold;
                    font-family: "Conv_IBMPlexSans-Regular";
                    font-size: 12px;
                    // color: #7f8fa4;
                    width: 220px;
                }

                .font-color {
                    color: #7f8fa4;
                }
            }
        }
    }

    &__status {
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 16px;

        .status-indicator {
            background-color: $primary-color;
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }

        .status1 {
            background-color: #9ed5ff;
        }

        .status2 {
            background-color: #9b9a9b;
        }

        .status3 {
            background-color: #3f51b5;
        }

        .status7 {
            background-color: #396a07;
        }

        .status-label {
            color: $primary-color;
            margin-top: 5px;
        }

        .date-label {
            font-weight: bold;
            font-family: "Conv_IBMPlexSans-Regular";
            font-size: 12px;
            // color: #7f8fa4;
            width: 200px;
        }

        .font-color {
            color: #7f8fa4;
        }

        .end-date {
            margin-top: 18px;
        }

    }
}


.remarks-popover-card {
    padding: 10px 10px 10px 10px;
    border-radius: $border-radius;
    width: 225px;
    overflow: auto;
    border: 1px solid $border-color;
    &__title {
        font-weight: bold;
        font-family: "Conv_IBMPlexSans-Regular";
        font-size: 14px;
        color: #7f8fa4;
        width: 220px;
    }

    &__remarks-text {
        font-weight: bold;
        font-family: "Conv_IBMPlexSans-Regular";
        font-size: 14px;
        // color: #7f8fa4;
        width: 200px;
    }

    &__text-area {
        border: rgb(0 0 0 / 22%) groove;
        padding: 3px;
        width: 220px
    }

    .space-between {
        display: flex;
        justify-content: space-between;
    }
    &__action-button{
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
    }

    .pb-8 {
        padding-bottom: 8px;
    }
    .remarks-field-height{
        height: 135px !important;
    }
}

.info-popover-card {
    padding: 10px 10px 10px 10px;
    border-radius: $border-radius;
    width: 250px;
    overflow: auto;
    border: 1px solid $border-color;
    &__title {
        // font-weight: bold;
        // font-family: "Conv_IBMPlexSans-Regular";
        font-size: 16px;
        // color: #7f8fa4;
    }
    .separator-line{
        border-bottom: 1px solid $border-color;
    }
    .space-between {
        display: flex;
        justify-content: space-between;
    }
    .genral-info{
        background-color: #2993FF;
      }
      .status-info{
        width: 10px;
        display: inline-block;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
        // font-family: 'Conv_IBMPlexSans-Medium';
      }
      .heading{
        font-size: 15px;
      }
      .sub-heading{
        font-size: 13px;
      }
      .initiated{
        background-color: #9ED5FF;
      }
      .launched,
      .inprogress{
        background-color: #9b9a9b;
      }
      .overdue{
        background-color: #FF0100;
      }
  
      .draft{
        background-color: #2c6fb2;
      }
  
      .review{
        background-color: #ecd910;
      }
  
      .approved{
        background-color: #89d153;
      }
  
      .published{
        background-color: #396a07;
      }
}

.pointer {
    cursor: pointer;
}

.status-indicator {
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 10px;
}