@import "/setting";
body {
 
}

.user-detail-modal{
  .user-profile-pic{
    margin-bottom:10px;
    p{
      font-family: $ibmplexSemiBold;
      font-size: 16px;
      color: #3f51b5;
      text-align: center;
      padding: 7px 0 3px;
      a{
        color: #3f51b5;
      }
    }
    img{
      width: 100px;
      border-radius: 50%;
      height: 100px;
      object-fit: cover;
      margin: 0 auto;
      display: block;
    }
  }
  ul{
    display: inline-block;
    width: 100%;
    background: #f1f1f1;
    padding: 12px;
    border-radius: 6px;
    label{
      font-family: $ibmplexRegular;
      font-size: 14px;
      color: #7a7a7a;
      text-align: left;
      font-weight: 100;
      top: 0px;
      position: relative;
    }
    p{
      font-family: $ibmplexRegular;
      font-size: 14px;
      color: #000000;
    }
    li{
      .first-block{
        width:50%;
      }
      .second-block{
        width:50%;
      }
    }
  }

}
.div-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-space-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.justify-content-end{
  display: flex;
  justify-content: end;
}
.align-center{
  display: flex;
  align-items: center;
}

.project-management-modal{
  .MuiDialogTitle-root{
    padding: 0px 24px;
    h2{
        @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
    }
}
.MuiDialogContent-root{
    padding: 5px 25px 25px 25px;
}
}
.modal-header-divider{
  border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}
.lessor-logo{
    object-fit: cover;
    width: 52px !important;
    margin-left: 0px;
    margin-right: 8px;
    display: block;
    max-height: 13px !important; 
    height: none !important;
}
.div-lessor-logo{
  height: 35px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-status{
  display: inline-block;
  text-align: center;
  width: 100px;
  font-size: $font13;
  border-radius: 3px;
  font-family: $ibmplexMedium;
  font-weight: 500;
  padding:3px 0;
  &.cancelled{
      background: #F5C7CA;
      color:#622439
  }
  &.draft{
      background: #EBD186;
      color: #7F5E0D;
  }
  &.initiated{
      background: #acacac;
      color: #000000;
  }
  &.launched{
      background: #7b8bff;
      color: #fff;
  }
  &.in-review{
      background: #573C7F;
      color: #D0BEEE;
  }
  &.completed{
      background: #93c49f;
      color: #264D1C;
  }
  &.approved{
      background: #63eac9;
      color: #264D1C;
  }
  &.archived{
      background: #9a9991;
      color: #264D1C;
  }
  &.available{
    background: #93c49f;
      color: #264D1C;
  }
  &.not_available{
    background: #F5C7CA;
      color:#622439
  }
  &.deployed{
    background: #acacac;
      color: #000000;
  }
}
.user-info-icon{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer
}

.cursor-pointer{
  cursor: pointer;
}
.bg-blue{
  background-color:  #3f51b5 !important;
}
.collapse-table-cell{
  width: 160px; background: #f3f3f3; font-size: 13px; font-family: Conv_IBMPlexSans-Regular;
}